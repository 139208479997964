import React, { ChangeEventHandler, FocusEventHandler, ReactElement } from 'react';
import { createStyles, StyleRules, WithStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const styles = (): StyleRules =>
  createStyles({
    root: {},
  });

const FTextField = ({
  classes,
  label,
  value,
  onChange,
  multiline = false,
  minRows,
  disabled = false,
  rows,
  id,
  inputProps,
  error,
  helperText,
  type,
  onBlur,
  name,
  autoFocus = true,
  defaultValue,
  required = false,
  placeholder,
}: Props): ReactElement => (
  <TextField
    variant="outlined"
    margin="normal"
    required={required}
    fullWidth
    autoFocus={autoFocus}
    id={id}
    label={label}
    name={name}
    value={value}
    multiline={multiline}
    minRows={multiline ? minRows : 1}
    onChange={onChange}
    rows={rows}
    className={classes.root}
    disabled={disabled}
    InputLabelProps={{
      shrink: true,
    }}
    inputProps={{ maxLength: inputProps }}
    error={error}
    helperText={helperText}
    type={type}
    onBlur={onBlur}
    defaultValue={defaultValue}
    placeholder={placeholder}
  />
);

export interface Props extends WithStyles<typeof styles> {
  className?: string;
  label?: string;
  value?: string | null | number;
  onChange?: ChangeEventHandler;
  multiline?: boolean;
  minRows?: number | string;
  disabled?: boolean;
  name?: string;
  id?: number;
  rows?: number | string;
  inputProps?: number | object;
  error?: boolean;
  helperText?: string;
  type?: string;
  onBlur?: FocusEventHandler;
  autoFocus?: boolean;
  defaultValue?: string;
  required?: boolean;
  placeholder?: string;
}

export default withStyles(styles)(FTextField);
