import React, { useEffect, useState } from 'react';
// import { useDispatch } from 'react-redux';
// import { useLazyQuery } from '@apollo/client';
import { Chip, Theme } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { createStyles, StyleRules, WithStyles, withStyles } from '@material-ui/styles';
import { DataGrid, GridColDef, GridRowData, GridRowParams } from '@mui/x-data-grid';
import { navigate } from 'gatsby';

// import { loadingEndAction, loadingStartAction } from '../../reducers/loadingReducer';
// import { showNotificationAction } from '../../reducers/notificationReducer';
// import debug from '../../utils/debug';
// import { GET_ALL_JOB_RQUESTS } from '../../utils/graphqlStrings';

export enum JobRequestStatusEnum {
  NONE = '未提交申请',
  APPLIED = '已提交申请',
  ACCEPTED = '已通过审核',
  DENIED = '未通过审核',
}

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      maxWidth: '80%',
      height: 900,
      margin: 'auto',
      paddingBottom: '5%',
      '& .grid-data-cell-NONE': {
        backgroundColor: theme.palette.grey,
        '&:hover': {
          backgroundColor: theme.palette.grey[500],
        },
      },
      '& .grid-data-cell-APPLIED': {
        backgroundColor: theme.palette.info.main,
        '&:hover': {
          backgroundColor: theme.palette.info.dark,
        },
      },
      '& .grid-data-cell-ACCEPTED': {
        backgroundColor: theme.palette.success.main,
        '&:hover': {
          backgroundColor: theme.palette.success.dark,
        },
      },
      '& .grid-data-cell-DENIED': {
        backgroundColor: theme.palette.error.main,
        '&:hover': {
          backgroundColor: theme.palette.error.dark,
        },
      },
    },
  });

const JobRequestListView = ({ classes }: Props): React.ReactElement => {
  // const dispatch = useDispatch();
  const [tableData, setTableData] = useState<GridRowData[]>([]);
  setTableData;
  const handleClickOpen = (data: GridRowParams): void => {
    if (!data) return;
    navigate('/admin/jobRequestDetail' + '#' + data.row.id, { state: { row: data.row.id } });
  };

  // const [getAllJobRequests, { data, loading, error }] = useLazyQuery(GET_ALL_JOB_RQUESTS, {
  //   fetchPolicy: 'no-cache',
  // });

  // useEffect(() => {
  //   getAllJobRequests();
  // }, []);

  // useEffect(() => {
  //   if (!loading) {
  //     dispatch(loadingStartAction());
  //   }
  //   if (error) {
  //     dispatch(showNotificationAction({ severity: 'error', message: '获取所有公司失败' }));
  //   }
  //   if (data && data.getAllJobRequests) {
  //     dispatch(loadingEndAction());
  //     debug(data.getAllJobRequests);
  //     setTableData(
  //       data.getAllJobRequests.map((c: any) => ({
  //         id: c.id,
  //         companyName: c.company?.companyName,
  //         title: c.basicInfo?.title,
  //         province: c.basicInfo?.province,
  //         city: c.basicInfo?.city,
  //         workType: c.detail?.workType,
  //         startTime: c.basicInfo?.startTime,
  //         status: c.status,
  //         displayedStatus: (JobRequestStatusEnum as any)[c.status || 'NONE'],
  //       }))
  //     );
  //   }
  // }, [data]);

  // if (loading) {
  //   dispatch(loadingStartAction());
  // } else {
  //   dispatch(loadingEndAction());
  // }

  // if (error) {
  //   dispatch(showNotificationAction('error', (error.message || error) as string));
  //   return <></>;
  // }
  const columns: GridColDef[] = [
    {
      headerName: '公司名称',
      field: 'companyName',
      width: 200,
      editable: true,
    },
    {
      headerName: '标题',
      field: 'title',
      width: 200,
      editable: true,
    },
    {
      headerName: '工作类型',
      field: 'workType',
      width: 200,
      editable: true,
    },
    {
      headerName: '省份',
      field: 'province',
      width: 200,
      editable: true,
    },
    {
      headerName: '城市',
      field: 'city',
      width: 200,
      editable: true,
    },
    {
      headerName: '开始时间',
      field: 'startTime',
      width: 200,
      editable: true,
    },
    {
      headerName: '审核状态',
      field: 'displayedStatus',
      width: 150,
      editable: false,
      renderCell: params => {
        return (
          <Chip
            label={params.formattedValue}
            className={`grid-data-cell-${params.row.status || 'NONE'}`}
          />
        );
      },
    },
  ];

  const [inputTextCompany, setInputTextCompany] = useState('');
  const inputHandlerCompany = (e: any) => {
    //convert input text to lower case
    const lowerCase = e.target.value.toLowerCase();
    setInputTextCompany(lowerCase);
  };

  const [inputTextTitle, setInputTextTitle] = useState('');
  const inputHandlerTitle = (e: any) => {
    //convert input text to lower case
    const lowerCase = e.target.value.toLowerCase();
    setInputTextTitle(lowerCase);
  };

  let filterData = [];

  const [filteredData, setFilteredData] = useState<GridRowData[]>([]);

  useEffect(() => {
    setFilteredData(tableData);
  }, [tableData]);

  const ListByCompany = (props: any): void => {
    filterData = tableData.filter((el: any) => {
      if (props.input === '') {
        return el;
      } else {
        return el.companyName.toLowerCase().includes(props.input);
      }
    });
    setFilteredData(filterData);
  };
  const ListByTitle = (props: any): void => {
    filterData = tableData.filter((el: any) => {
      if (props.input === '') {
        return el;
      } else {
        return el.title.toLowerCase().includes(props.input);
      }
    });
    setFilteredData(filterData);
  };

  useEffect(() => {
    ListByCompany({ input: inputTextCompany });
  }, [inputTextCompany]);

  useEffect(() => {
    ListByTitle({ input: inputTextTitle });
  }, [inputTextTitle]);

  return (
    <>
      <div className={classes.root}>
        <div style={{ margin: 6 }}>
          <TextField
            id="outlined-basic"
            onChange={inputHandlerCompany}
            variant="standard"
            label="🔍 公司搜索"
            style={{ width: 200 }}
          />
          <TextField
            id="outlined-basic"
            onChange={inputHandlerTitle}
            variant="standard"
            label="🔍 标题搜索"
            style={{ width: 200 }}
          />
        </div>
        <DataGrid
          disableSelectionOnClick={true}
          rows={filteredData}
          columns={columns}
          rowHeight={40}
          onRowClick={handleClickOpen}
          className={classes.dataGrid}
        />
      </div>
    </>
  );
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
}

export default withStyles(styles)(JobRequestListView);
