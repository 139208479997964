import React, { MouseEventHandler, ReactElement } from 'react';
import Button from '@material-ui/core/Button';
import { createStyles, StyleRules, Theme, WithStyles, withStyles } from '@material-ui/core/styles';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      margin: theme.spacing(3, 0, 2),
    },
  });

const FButton = ({
  classes,
  onClick,
  disabled = false,
  children,
  ...rest
}: Props): ReactElement => (
  <Button
    type="button"
    fullWidth
    variant="contained"
    color="primary"
    className={classes.submit}
    onClick={onClick}
    disabled={disabled}
    {...rest}
  >
    {children}
  </Button>
);

export interface Props extends WithStyles<typeof styles> {
  className?: string;
  onClick?: MouseEventHandler;
  disabled?: boolean;
  children: string;
}

export default withStyles(styles)(FButton);
