import React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { alpha, createStyles, StyleRules, Theme } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Button } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import AccountCircle from '@material-ui/icons/AccountCircle';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import SearchIcon from '@material-ui/icons/Search';
import { Link } from 'gatsby';
import { navigate } from 'gatsby-link';

import { logout } from '../../actions/userActions';
import logo from '../../images/fintegrity-logo.png';
import AdminLayout from '../../layout/AdminLayout';
import { AppState } from '../../reducers';
import { FUserTypeEnum } from '../../reducers/userReducer';
import JobRequestDetailView from '../../views/admin/JobRequestDetailView';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    toolbar: {
      width: 1128,
      margin: 'auto',
      height: theme.spacing(3),
    },
    img: {
      width: 40,
      marginBottom: 0,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    usernameDisplay: {
      paddingLeft: '4px',
      fontSize: '0.7em',
    },
    menuText: {
      fontSize: '1.2em',
    },
    noAdmin: {
      height: 1000,
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
    },
    noAdminItem: {
      margin: '1%',
    },
  });

const ConsultantDetail = ({ location, classes }: any): React.ReactElement => {
  const dispatch = useDispatch();
  const user = useSelector((appState: AppState) => appState.user);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [email, setEmail] = useState<string>(user.email || '');

  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (): void => {
    setAnchorEl(null);
  };

  const handleLogout = async (): Promise<void> => {
    setAnchorEl(null);
    await dispatch(logout(user.userType));
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {/* <MenuItem onClick={handleMenuClose}>Profile</MenuItem> */}
      {/* <MenuItem onClick={handleMenuClose}>My account</MenuItem> */}
      <MenuItem onClick={handleLogout}>退出登录</MenuItem>
    </Menu>
  );

  useEffect(() => {
    setEmail(user.email || '');
  }, [user]);

  if (location.state?.row !== undefined) {
    return (
      <AdminLayout>
        {/* Header */}
        <div className={classes.grow}>
          <AppBar position="static">
            <Toolbar className={classes.toolbar}>
              <Link
                to={
                  user.userType === FUserTypeEnum.CONSULTANT
                    ? '/consultant'
                    : user.userType === FUserTypeEnum.COMPANY
                    ? '/company'
                    : '/admin'
                }
              >
                <img src={logo} alt="logo" className={classes.img} />
              </Link>
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Search…"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ 'aria-label': 'search' }}
                />
              </div>
              <div className={classes.grow} />
              <div className={classes.sectionDesktop}>
                <Button
                  aria-label="Company list"
                  color="inherit"
                  onClick={() => {
                    navigate('/admin/companies');
                  }}
                >
                  <PlaylistAddCheckIcon />
                  <span className={classes.menuText}>企业列表</span>
                </Button>
                <Button
                  aria-label="Consultant list"
                  color="inherit"
                  onClick={() => {
                    navigate('/admin/consultant');
                  }}
                >
                  <PlaylistAddCheckIcon />
                  <span className={classes.menuText}>顾问列表</span>
                </Button>
                <Button
                  aria-label="Job request list"
                  variant="contained"
                  onClick={() => {
                    navigate('/admin/jobRequest');
                  }}
                >
                  <PlaylistAddCheckIcon />
                  <span className={classes.menuText}>工单列表</span>
                </Button>
                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >
                  <AccountCircle />
                  <Typography className={classes.usernameDisplay}>{email}</Typography>
                </IconButton>
              </div>
            </Toolbar>
          </AppBar>
          {renderMenu}
        </div>

        {/* Body */}
        <JobRequestDetailView jobRequest={location.state?.row} />
      </AdminLayout>
    );
  } else {
    return <></>;
  }
};

export interface Props extends WithStyles<typeof styles> {
  title?: string;
}

export default withStyles(styles)(ConsultantDetail);
